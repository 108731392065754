import clsx from 'clsx';
import WidgetHeading from 'components/pageContents/atoms/widgetHeading/widgetHeading.component';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ALL } from 'shared/helpers/constants';
import { Obligation } from 'shared/models/HomePageModel';
import { useAppSelector } from 'shared/redux/hooks';
import { selectHomePageData } from 'shared/redux/slice/homePageDataSlice';
import { RootState } from 'shared/redux/store';

import styles from './totalQuantitativeRequirements.module.scss';

const TotalQuantitativeRequirements = (): JSX.Element => {
  const [totalQuantitativeRequirements, setTotalQuantitativeRequirements] = useState<any>(null);

  // fetching store data
  const { homePageData } = useAppSelector(selectHomePageData);
  const { quantitativeObligations } = homePageData || {};

  const selectedRegulation = useSelector((state: RootState) => {
    return state.homePageConfigSlice.selectedRegulation;
  });

  useEffect(() => {
    if (selectedRegulation && quantitativeObligations) {
      const allOption = {
        regulationName: ALL,
        value: quantitativeObligations.reduce((acc: number, curr: any) => acc + curr.value, 0)
      };
      const updatedQuantitativeRequirements = [...quantitativeObligations, allOption];
      const selectedMetrics = updatedQuantitativeRequirements.filter(
        (value: Obligation) => value.regulationName == selectedRegulation
      );
      if (selectedMetrics.length > 0) {
        setTotalQuantitativeRequirements(selectedMetrics[0].value);
      }
    }
  }, [selectedRegulation, quantitativeObligations]);

  return (
    <div className="motif-container no-padding">
      <WidgetHeading title="Total Quantitative Requirements" />
      <div className={clsx(styles.innerContainer, 'motif-row')}>
        <h1 className={clsx(styles.noHeight, 'motif-h1', 'no-padding')}>
          {totalQuantitativeRequirements}
        </h1>
      </div>
    </div>
  );
};
export default TotalQuantitativeRequirements;
