import MotifTabNavigation, { MotifTabControl } from '@ey-xd/motif-react/components/tab-navigation';
import clsx from 'clsx';
import DonutChart from 'components/pageContents/atoms/donutChart/donutChart.component';
import WidgetHeading from 'components/pageContents/atoms/widgetHeading/widgetHeading.component';
import React, { useEffect, useMemo, useState } from 'react';
import {
  DisclosureMetrics,
  IDonutInterface,
  IMetricExplorerData
} from 'shared/models/HomePageModel';
import { useAppStore } from 'shared/redux/hooks';
import {
  camelCaseToWords,
  generateDonutData,
  generateMetricExplorerData,
  generateMetricExplorerDataSap
} from 'shared/utils/utils';

import styles from './metricExplorer.module.scss';

interface MetricExplorerProps {
  metricExplorerData?: IMetricExplorerData;
}

const defaultTabKey = 2;
const defaultTabValue = 'environmental';

const MetricExplorer: React.FC<MetricExplorerProps> = ({ metricExplorerData }) => {
  const [donutData, setDonutData] = useState<IDonutInterface[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>(defaultTabValue);
  const [metricCount, setMetricsCount] = useState<number>(0);

  useEffect(() => {
    setUpDonutData(metricExplorerData, selectedTab);
  }, [metricExplorerData]);

  const setUpDonutData = (disclosureMetrics: any, key: string) => {
    if (disclosureMetrics && disclosureMetrics[key]) {
      const { value, ...otherData } = disclosureMetrics[key];
      const donutDataGenerated: IDonutInterface[] = generateDonutData(otherData);
      setDonutData(donutDataGenerated);
      setMetricsCount(value);
    } else {
      setDonutData([]);
      setMetricsCount(0);
    }
  };

  const onTabChange = (key: string) => {
    setSelectedTab(key);
    setUpDonutData(metricExplorerData, key);
  };

  return (
    <div className="motif-container no-padding">
      <WidgetHeading
        info={{
          show: true,
          tooltipPlacement: 'bottom',
          tooltipContent: ['Primary disclosure metrics categorized by E, S & G topics.']
        }}
        title="Primary Disclosures Metrics Explorer"
      />
      <div>Select an area below to change view</div>
      <div className={clsx('motif-row', styles.motifContainer)}>
        <MotifTabNavigation defaultActiveKey={defaultTabKey} className={styles.widthComplete}>
          {metricExplorerData &&
            Object.keys(metricExplorerData).map((metric, index) => {
              return (
                <MotifTabControl
                  key={index + metric}
                  onClick={() => onTabChange(metric)}
                  className={clsx(
                    styles.tabPadding,
                    styles.widthComplete,
                    selectedTab === metric && styles.selectedTabColor
                  )}>
                  {metric && camelCaseToWords(metric)}
                </MotifTabControl>
              );
            })}
        </MotifTabNavigation>
        <div className={clsx(styles.fullWidth, 'no-padding')}>
          <DonutChart data={donutData} metricCount={metricCount} />
        </div>
      </div>
    </div>
  );
};
export default MetricExplorer;

export const MetricExplorerContainerSap: React.FC = () => {
  const {
    sapHomePageData: { metricExplorer },
    selectedRegulation
  } = useAppStore().sapHomePageSlice;

  const metricData = useMemo<IMetricExplorerData | undefined>(() => {
    return selectedRegulation
      ? generateMetricExplorerDataSap(metricExplorer, selectedRegulation)
      : undefined;
  }, [selectedRegulation]);

  return <MetricExplorer metricExplorerData={metricData} />;
};

export const MetricExplorerContainer: React.FC = () => {
  const { selectedRegulation } = useAppStore().homePageConfigSlice;
  const { homePageData } = useAppStore().homePageSlice;

  const metricData = useMemo<IMetricExplorerData | undefined>(() => {
    return selectedRegulation
      ? generateMetricExplorerData(
          homePageData?.disclosureMetrics as DisclosureMetrics[],
          selectedRegulation
        )
      : undefined;
  }, [selectedRegulation]);
  return <MetricExplorer metricExplorerData={metricData} />;
};
