import { SankeyExtraProperties, SankeyNode } from 'd3-sankey';
import { msalInstance } from 'msalConfig';
import {
  InitialRegulatoryNodeData,
  InitialSapNodeData,
  InitialThematicNodeData
} from 'shared/data/sankeyColumnDefinition';
import { ALL, REGULATIONS_COLOR_CODE, colors, logoutUrl } from 'shared/helpers/constants';
import { SelectBox } from 'shared/models/AppModel';
import {
  Categories,
  DisclosureMetrics,
  IDonutInterface,
  IMetricExplorerData
} from 'shared/models/HomePageModel';
import { ExtraNodeProperties, LineagePopupData } from 'shared/models/Sankey';
import { IMetricExplorer, customSakeyModal } from 'shared/models/sapHomePageModel';
import { httpClientDotNet } from 'shared/utils/httpClient';

export const formatArrayForSelectBox = (inputData: string[]): SelectBox[] => {
  const all = {
    key: ALL,
    value: 'All'
  };
  const data = inputData.map((item) => {
    return {
      key: item,
      value: item
    };
  });
  return [all, ...data];
};

export const camelCaseToWords = (s: string) => {
  const str = s.replace(/((?!^)[A-Z])/g, ' $1');
  const result = str.replace(/_/g, '');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const generateDonutData = (inputData: Record<string, number>): IDonutInterface[] => {
  const donutData: IDonutInterface[] = Object.keys(inputData)
    .filter((key) => {
      return key != 'value';
    })
    .map((data: string, index: number) => {
      return {
        name: data,
        value: inputData[data],
        color: colors[index]
      };
    });
  return donutData;
};

export const formatOverlapAnalysis = (inputData: any): any => {
  if (inputData) {
    const data = {};
    for (const val of inputData) {
      Object.assign(data, val);
    }
    return data;
  }
  return [];
};

export const extractNodes = (
  intermittentNodeData: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[],
  regData: any,
  levelX: number,
  levelY: number,
  parentKey: string,
  modalOpenLevel: number
) => {
  if (regData && regData.length === 0) return [];
  else {
    if (Array.isArray(regData))
      regData.map((data) => {
        const keys = Object.keys(data);
        const { nodeId, value, ...otherValues } = data;
        keys.map((objKey: any) => {
          if (objKey == 'value') {
            if (levelX == modalOpenLevel) {
              intermittentNodeData.push({
                key: nodeId,
                name: value || '',
                levelX: levelX,
                levelY: levelY,
                parentKey: parentKey,
                isSelected: false,
                isExpanded: false,
                extraData: {
                  count: data['count'] || null,
                  modalData: otherValues as Partial<LineagePopupData>
                }
              });
            } else
              intermittentNodeData.push({
                key: nodeId,
                name: value || '',
                levelX: levelX,
                levelY: levelY,
                parentKey: parentKey,
                isSelected: false,
                isExpanded: false,
                extraData: {
                  count: data['count'] || null
                }
              });
          }
        });
        levelY++;
      });
  }
};

export const processRegulatoryData = (
  inComingData: any,
  columnId: number,
  parentKey: string
): any => {
  if (inComingData) {
    const nodeData = InitialRegulatoryNodeData.filter((value) => {
      return value.levelX == columnId;
    });
    extractNodes(nodeData, inComingData, columnId, 1, parentKey, 8);
    return nodeData;
  }
  return [];
};

export const generateMetricExplorerDataSap = (
  metricExplorer: IMetricExplorer[],
  regulation: string
): IMetricExplorerData | undefined => {
  const metricForRegulation: IMetricExplorer | undefined = metricExplorer.find(
    (data) => data.regulationName.toLowerCase() == regulation
  );
  if (metricForRegulation) {
    return {
      general: {
        ...metricForRegulation.general.topics,
        value: metricForRegulation.general.total
      },
      social: { ...metricForRegulation.social.topics, value: metricForRegulation.social.total },
      environmental: {
        ...metricForRegulation.environmental.topics,
        value: metricForRegulation.environmental.total
      },
      governance: {
        ...metricForRegulation.governance.topics,
        value: metricForRegulation.governance.total
      }
    };
  }
};

const getAggregatedMetrics = (metricExplorer: DisclosureMetrics[]) => {
  const aggregatedOutput = {
    metrics: {
      primary: {
        value: 0,
        environmental: {
          value: 0
        },
        social: {
          value: 0
        },
        general: {
          value: 0
        },
        governance: {
          value: 0
        }
      }
    },
    regulationName: ALL
  };
  metricExplorer.forEach((regulation) => {
    const primaryMetrics = regulation.metrics.primary;
    aggregatedOutput.metrics.primary.value += primaryMetrics.value;

    Object.keys(primaryMetrics).forEach((category) => {
      if (category !== 'value') {
        const typedCategory = category as Categories;
        if (!aggregatedOutput.metrics.primary[typedCategory]) {
          aggregatedOutput.metrics.primary[typedCategory] = { value: 0 };
        }
        const categoryMetrics = primaryMetrics[typedCategory];
        aggregatedOutput.metrics.primary[typedCategory].value += categoryMetrics.value;

        Object.keys(categoryMetrics).forEach((metric) => {
          if (metric !== 'value') {
            if (
              !(aggregatedOutput.metrics.primary[category as Categories] as Record<string, number>)[
                metric
              ]
            ) {
              (aggregatedOutput.metrics.primary[category as Categories] as Record<string, number>)[
                metric
              ] = 0;
            }
            (aggregatedOutput.metrics.primary[category as Categories] as Record<string, number>)[
              metric
            ] += categoryMetrics[metric];
          }
        });
      }
    });
  });

  const aggregatedMetrics = aggregatedOutput.metrics.primary;
  return {
    general: aggregatedMetrics.general || {},
    social: aggregatedMetrics.social || {},
    environmental: aggregatedMetrics.environmental || {},
    governance: aggregatedMetrics.governance || {}
  };
};

export const generateMetricExplorerData = (
  metricExplorer: DisclosureMetrics[],
  regulation: string
): IMetricExplorerData | undefined => {
  regulation = regulation.toUpperCase();

  if (regulation === ALL) {
    return getAggregatedMetrics(metricExplorer);
  } else {
    const metricForRegulation: DisclosureMetrics | undefined = metricExplorer.find(
      (data) => data.regulationName.toUpperCase() === regulation
    );
    if (metricForRegulation) {
      return {
        general: metricForRegulation.metrics.primary.general,
        social: metricForRegulation.metrics.primary.social,
        environmental: metricForRegulation.metrics.primary.environmental,
        governance: metricForRegulation.metrics.primary.governance
      };
    }
  }
};

export const processSapRegulatoryData = (
  inComingData: any,
  columnId: number,
  parentKey: string
): any => {
  if (inComingData) {
    const nodeData = InitialSapNodeData.filter((value) => {
      return value.levelX == columnId;
    });
    extractNodes(nodeData, inComingData, columnId, 1, parentKey, 8);
    return nodeData;
  }
  return [];
};

const generateNodeObject = (
  key: string,
  value: string,
  levelX: number,
  levelY: number,
  parentKey: string,
  extraData: any
): customSakeyModal => {
  return {
    key,
    name: value || '',
    levelX,
    levelY,
    parentKey,
    isSelected: false,
    isExpanded: false,
    extraData
  };
};

const buildChipObject = (name: string) => {
  return { name: name, color: REGULATIONS_COLOR_CODE[name as keyof typeof REGULATIONS_COLOR_CODE] };
};

export const extractNodesThematic = (
  intermittentNodeData: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[],
  regData: any,
  levelX: number,
  levelY: number,
  parentKey: string,
  popupShowingLevel: number
) => {
  if (Array.isArray(regData) && regData.length <= 0) return [];
  else {
    regData.map((data: any) => {
      const { nodeId, value, ...otherValues } = data;

      intermittentNodeData.push(
        generateNodeObject(nodeId, value, levelX, levelY++, parentKey, {
          ...((data?.count || data?.counts) && { count: data?.count || data?.counts }),
          ...(levelX == popupShowingLevel && {
            modalData: (otherValues as Partial<LineagePopupData>) || undefined
          }),
          ...(levelX == 4 && {
            dotChips: data?.regulationName ? [buildChipObject(data?.regulationName)] : []
          }),
          ...(levelX == 3 && {
            regulationCount: data?.regulation || []
          }),
          ...(levelX == 5 && {
            normalChips: data?.overlappedObligations
              ? data?.overlappedObligations.map((item: any) => buildChipObject(item.regulation))
              : []
          }),
          ...(data?.regulationName && { regulationName: data.regulationName || undefined })
        })
      );
    });
  }
};

export const processThematicRegulatoryData = (
  inComingData: any,
  columnId: number,
  parentKey: string
): any => {
  try {
    if (inComingData) {
      const popupLevel = 7;
      const levelY = 1;
      const nodeData = InitialThematicNodeData.filter((value) => {
        return value.levelX == columnId;
      });
      extractNodesThematic(nodeData, inComingData, columnId, levelY, parentKey, popupLevel);
      return nodeData;
    }
    return [];
  } catch (error) {
    console.log('🚀 ~ error: processThematicRegulatoryData', error);
    return [];
  }
};

export const convertToNormalString = (input: string) => {
  return input.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
};

export const logoutService = () => {
  return httpClientDotNet.post(logoutUrl).then(() => {
    msalInstance.logoutRedirect();
  });
};
