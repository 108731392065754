import {
  MotifCheckbox,
  MotifChip,
  MotifDropdown,
  MotifDropdownItem,
  MotifIcon
} from '@ey-xd/motif-react';
import clsx from 'clsx';
import FrameworkBarChart from 'components/pageContents/atoms/frameworkBarChart/frameworkBarChart.component';
import VennDiagram from 'components/pageContents/atoms/vennDiagram/vennDiagram.component';
import WidgetHeading from 'components/pageContents/atoms/widgetHeading/widgetHeading.component';
import { CommonIcons } from 'images/commonIcons';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { ALL, REGULATIONS_COLOR_CODE } from 'shared/helpers/constants';
import { BarDataModal, OverlapAnalysisModal, OverlappingItem } from 'shared/models/HomePageModel';
import { useAppDispatch, useAppStore } from 'shared/redux/hooks';
import { setOAMCheckbox, setOAMetrics } from 'shared/redux/slice/homePageConfigSlice';
import { homePageRequests } from 'shared/utils/requests/homePage';
import { convertToNormalString } from 'shared/utils/utils';

import styles from './overlapAnalysis.module.scss';

interface OverlapAnalysisProps {
  frameworksRegulations: string[];
  selectedRegulation: string;
  overLapData: OverlapAnalysisModal | undefined;
}

const OverlapAnalysis: React.FC<OverlapAnalysisProps> = ({
  frameworksRegulations,
  selectedRegulation,
  overLapData
}) => {
  const dispatch = useAppDispatch();

  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(350);
  const [chartHeight, setChartHeight] = useState(350);

  const [allRegulations, setAllRegulations] = useState<string[]>();
  const [overlapBarChartData, setOverlapBarChartData] = useState<BarDataModal>([]);
  const [vennDiagramData, setVennDiagramData] = useState<any>(null); // Todo: change this "any" later when using actual data
  const [isDropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [makeSelectAllChecked, setSelectAll] = useState<boolean>(true);

  const overlapAnalysisDropDownMap: any = {
    calculations: 'Metrics',
    coreAttribute: 'Core Data Attributes'
  };

  // store data
  const { selectedOAMCheckbox, selectedOAMetrics } = useAppStore().homePageConfigSlice;

  // On page load
  useEffect(() => {
    if (
      selectedRegulation &&
      selectedRegulation !== '' &&
      frameworksRegulations &&
      frameworksRegulations.length > 0 &&
      overLapData
    ) {
      const filteredRegulations = frameworksRegulations.filter(
        (obj: string) => obj && obj !== selectedRegulation
      );

      setAllRegulations(filteredRegulations);
      setSelectAll(true);
      dispatch(setOAMetrics(overLapData && Object.keys(overLapData)[0]));
      const checkboxData = filteredRegulations.map((obj: any) => obj);
      dispatch(setOAMCheckbox(checkboxData));

      generateGraphsData(
        selectedRegulation,
        overLapData && Object.keys(overLapData)[0],
        checkboxData,
        overLapData
      );
    }
  }, [selectedRegulation, frameworksRegulations, overLapData]);

  const onHeaderSelectBoxChange = (value: string) => {
    dispatch(setOAMetrics(value));
    generateGraphsData(selectedRegulation, value, selectedOAMCheckbox, overLapData);
  };

  const generateGraphsData = (
    activeRegulation: string,
    activeMetrics: string,
    checkedRegulations: string[],
    overLapDetails: OverlapAnalysisModal | undefined
  ) => {
    if (!overLapDetails || Object.keys(overLapDetails).length == 0) {
      return false;
    }

    const regulationData = overLapDetails?.[activeMetrics].filter((data) => {
      if (data.regulationA === activeRegulation || data.regulationB === activeRegulation)
        return true;
    });

    const generalOverlap: OverlappingItem[] = [];
    const environmentOverlap: OverlappingItem[] = [];
    const socialOverlap: OverlappingItem[] = [];
    const governanceOverlap: OverlappingItem[] = [];
    const venDataSet = new Set();
    const singleObjTrackingSet = new Set();

    for (const item of regulationData) {
      const {
        regulationA,
        regulationB,
        totalOverlapCount,
        totalOverlapPercentage,
        totalCountA,
        totalCountB,
        generalOverlapPercentage,
        environmentalOverlapPercentage,
        governanceOverlapPercentage,
        socialOverlapPercentage
      } = item;
      const otherRegulation = regulationA == activeRegulation ? regulationB : regulationA;

      if (!singleObjTrackingSet.has(activeRegulation)) {
        if (regulationA == activeRegulation && totalCountA >= 0) {
          venDataSet.add({
            sets: [activeRegulation],
            size: 600,
            percentage: null,
            viewPercentage: null,
            totalOverlapCount: totalCountA
          });
          singleObjTrackingSet.add(activeRegulation);
        }
        if (regulationB == activeRegulation && totalCountB >= 0) {
          venDataSet.add({
            sets: [activeRegulation],
            size: 600,
            percentage: null,
            viewPercentage: null,
            totalOverlapCount: totalCountB
          });
          singleObjTrackingSet.add(activeRegulation);
        }
      }

      if (checkedRegulations.includes(otherRegulation.toUpperCase())) {
        const obj = {
          sets: [activeRegulation, otherRegulation],
          size: totalOverlapPercentage == 0 ? 0 : 100,
          percentage: totalOverlapPercentage == 0 ? 0 : 20,
          viewPercentage: totalOverlapPercentage,
          totalOverlapCount: totalOverlapCount
        };
        const objA = {
          sets: [regulationA],
          size: regulationA == activeRegulation ? 500 : 300,
          percentage: regulationA == activeRegulation ? 100 : 20,
          totalOverlapCount: totalCountA
        };
        const objB = {
          sets: [regulationB],
          size: regulationB == activeRegulation ? 500 : 300,
          percentage: regulationB == activeRegulation ? 100 : 20,
          totalOverlapCount: totalCountB
        };
        if (!venDataSet.has(obj)) {
          venDataSet.add(obj);
        }
        if (!singleObjTrackingSet.has(objA.sets[0])) {
          if (!(objA.sets[0] == activeRegulation && objA.size == 0)) {
            venDataSet.add(objA);
            singleObjTrackingSet.add(objA.sets[0]);
          }
        }
        if (!singleObjTrackingSet.has(objB.sets[0])) {
          if (!(objB.sets[0] == activeRegulation && objB.size == 0)) {
            venDataSet.add(objB);
            singleObjTrackingSet.add(objB.sets[0]);
          }
        }
        generalOverlap.push({
          percentage: generalOverlapPercentage == null ? 0 : generalOverlapPercentage,
          regulationName: otherRegulation
        });
        environmentOverlap.push({
          percentage: environmentalOverlapPercentage == null ? 0 : environmentalOverlapPercentage,
          regulationName: otherRegulation
        });
        socialOverlap.push({
          percentage: socialOverlapPercentage == null ? 0 : socialOverlapPercentage,
          regulationName: otherRegulation
        });
        governanceOverlap.push({
          percentage: governanceOverlapPercentage == null ? 0 : governanceOverlapPercentage,
          regulationName: otherRegulation
        });
      }
    }
    const barChart: BarDataModal = [
      {
        heading: 'General Overlap',
        overlapping: generalOverlap
      },
      {
        heading: 'Environment Overlap',
        overlapping: environmentOverlap
      },
      {
        heading: 'Social Overlap',
        overlapping: socialOverlap
      },
      {
        heading: 'Governance Overlap',
        overlapping: governanceOverlap
      }
    ];
    setOverlapBarChartData(barChart);
    setVennDiagramData(Array.from(venDataSet));
  };

  // this will reset the venn diagram when screen size change
  useEffect(() => {
    const updateWidth = () => {
      if (chartContainerRef.current) {
        setChartWidth(chartContainerRef.current.clientWidth);
      }
    };

    if (chartContainerRef.current) {
      setChartHeight(chartContainerRef.current.clientHeight);
    }
    // Initial width update
    updateWidth();
    // Event listener for window resize
    window.addEventListener('resize', updateWidth);
    // Cleanup the event listener when the component unmounts
    // Event listener for component resize

    const observer = new ResizeObserver((entries) => {
      setChartWidth(entries[0].contentRect.width);
    });
    if (chartContainerRef.current) {
      observer.observe(chartContainerRef.current);
    }
    return () => {
      window.removeEventListener('resize', updateWidth);
      chartContainerRef.current && observer.unobserve(chartContainerRef.current);
    };
  }, [chartContainerRef.current]);

  useEffect(() => {
    return () => {
      dispatch(setOAMetrics(''));
      dispatch(setOAMCheckbox([]));
    };
  }, []);

  const onFilterChange = (filter: string) => {
    // Check if all regulations are selected
    const allSelected = _.isEqual(selectedOAMCheckbox, allRegulations);

    if (filter === 'Select all') {
      // Toggle between selecting all and deselecting all
      const selectedFilterOptions = allSelected ? [] : allRegulations;
      if (selectedFilterOptions) {
        dispatch(setOAMCheckbox(selectedFilterOptions));
        generateGraphsData(
          selectedRegulation,
          selectedOAMetrics,
          selectedFilterOptions,
          overLapData
        );
      }
      setSelectAll(!allSelected);
    } else {
      const values = new Set(selectedOAMCheckbox);

      // Toggle selection for the specific filter
      if (values.has(filter)) {
        values.delete(filter);
      } else {
        values.add(filter);
      }

      // Convert set back to array
      const newArray = Array.from(values);

      // Check if all regulations are selected after the change
      const allSelectedAfterChange =
        newArray.length === allRegulations?.length &&
        allRegulations.every((regulation) => newArray.includes(regulation));

      setSelectAll(allSelectedAfterChange);
      generateGraphsData(selectedRegulation, selectedOAMetrics, newArray, overLapData);
      dispatch(setOAMCheckbox(newArray));
    }
  };

  const handleClickPopover = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  if (isEmptyObject(overLapData) && selectedOAMetrics == undefined) {
    return null;
  }
  return (
    <div className="motif-container no-padding">
      <WidgetHeading
        title="Overlap Analysis"
        showSelectBox={{
          data: overLapData
            ? Object.keys(overLapData).map((key) => {
                return {
                  key: key,
                  // value: key != 'coreAttribute' ? camelCaseToWords(key) : 'Core Data Attributes'
                  value: overlapAnalysisDropDownMap[key]
                };
              })
            : [{ key: '', value: '' }],
          selectedValue: selectedOAMetrics,
          onchange: onHeaderSelectBoxChange
        }}
      />

      {selectedOAMetrics && (
        <div>
          <MotifDropdown
            className="overlapAnalysisDropdownContainer"
            style={{ width: '100%' }}
            handleClickOutside={() => setDropdownVisible(false)}
            placement="bottom-left"
            trigger={
              <div
                className={clsx(
                  'motif-row',
                  'motif-center-xs',
                  'motif-middle-xs',
                  styles.overlapAnalysisChips
                )}>
                <div className={styles.selectedRegulations}>
                  {selectedOAMCheckbox && (
                    <>
                      {selectedOAMCheckbox.slice(0, 2).map((item: any) => (
                        <MotifChip
                          key={item}
                          removable={true}
                          onRemove={() => onFilterChange(item)}>
                          <div style={{ display: 'flex' }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none">
                              <circle
                                cx="4"
                                cy="4"
                                r="3"
                                fill={
                                  REGULATIONS_COLOR_CODE[
                                    convertToNormalString(
                                      item
                                    ) as keyof typeof REGULATIONS_COLOR_CODE
                                  ]
                                }
                              />
                            </svg>
                            <span className={styles.regulationNameCheckbox}>{item}</span>
                          </div>
                        </MotifChip>
                      ))}
                      {selectedOAMCheckbox.length > 2 && (
                        <MotifChip>
                          <div style={{ display: 'flex' }}>
                            <span className={styles.regulationNameCheckbox}>
                              +{selectedOAMCheckbox.length - 2}
                            </span>
                          </div>
                        </MotifChip>
                      )}
                    </>
                  )}
                </div>
                <MotifIcon
                  className={styles.showDropdownIcon}
                  src={`data:image/svg+xml;base64,${CommonIcons.downArrowSvg}`}
                  onClick={handleClickPopover}></MotifIcon>
              </div>
            }
            open={isDropdownVisible}>
            <MotifDropdownItem className={styles.dropdownItem} type="container">
              <div className={styles.overlapAnalysisCheckboxContainer}>
                <MotifCheckbox
                  className={styles.selectALlCheckbox}
                  key="Select all"
                  checked={makeSelectAllChecked}
                  id={`checkbox-1`}
                  name={`checkbox-1`}
                  onChange={() => {
                    onFilterChange('Select all');
                  }}
                  value="Select all">
                  Select all
                </MotifCheckbox>
                {allRegulations &&
                  allRegulations.map((filterName: string, index: number) => (
                    <MotifCheckbox
                      key={index}
                      checked={selectedOAMCheckbox.includes(filterName)}
                      id={`checkbox-${filterName}`}
                      name={`checkbox-${filterName}`}
                      onChange={() => {
                        onFilterChange(filterName);
                      }}
                      value={filterName}>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none">
                          <circle
                            cx="4"
                            cy="4"
                            r="3"
                            fill={
                              REGULATIONS_COLOR_CODE[
                                convertToNormalString(
                                  filterName
                                ) as keyof typeof REGULATIONS_COLOR_CODE
                              ]
                            }
                          />
                        </svg>
                        <span className={styles.regulationNameCheckbox}>{filterName}</span>
                      </div>
                    </MotifCheckbox>
                  ))}
              </div>
            </MotifDropdownItem>
          </MotifDropdown>
          <div
            className={clsx('motif-row', styles.marginAuto)}
            ref={chartContainerRef}
            style={{ width: '100%', height: '100%' }}>
            <div
              className={clsx(styles.vennDiagramContainer)}
              style={{ width: '100%', height: '100%' }}>
              {vennDiagramData && selectedOAMetrics && (
                <VennDiagram
                  dataSet={vennDiagramData}
                  chartWidth={Math.max(chartWidth, 300)}
                  chartHeight={Math.max(chartHeight, 300)}
                />
              )}
            </div>
          </div>
          <div className="motif-row  no-margin">
            <div className="motif-col-xs no-padding">
              <p> Overlap between selected Reporting Frameworks</p>
            </div>
          </div>
        </div>
      )}
      {overlapBarChartData &&
        overlapBarChartData.map((item) => (
          <div
            className={clsx('motif-row', 'no-margin', styles.frameworkOverlap)}
            key={item.heading}>
            <FrameworkBarChart
              heading={item.heading}
              overlapping={item.overlapping}
              regulationName={selectedRegulation.toUpperCase()}
            />
          </div>
        ))}
    </div>
  );
};
export default OverlapAnalysis;

const sapDummyRegulationForOverlapAnalysis = [
  'CSRD',
  'TCFD',
  'SFDR',
  'PCAF',
  'ISSB',
  'US SEC',
  'B-15'
];

export const OverlapAnalysisContainerSap = () => {
  const { selectedRegulation, sapHomePageData } = useAppStore().sapHomePageSlice;
  return (
    <>
      {selectedRegulation && (
        <OverlapAnalysis
          frameworksRegulations={sapDummyRegulationForOverlapAnalysis}
          selectedRegulation={selectedRegulation}
          overLapData={sapHomePageData?.overlapAnalysis}
        />
      )}
    </>
  );
};

export const OverlapAnalysisContainer = () => {
  let { selectedRegulation } = useAppStore().homePageConfigSlice;
  const defaultRegulation =
    useAppStore().homePageSlice.homePageData?.frameworksRegulations[0] || '';
  const { homePageData } = useAppStore().homePageSlice;
  const [overlapAnalysisValues, setOverlapAnalysisValues] = useState<OverlapAnalysisModal>({});

  const [actualRegulations, setActualRegulations] = useState<string[]>([]);

  function formatOverlapAnalysisData(inputData: any) {
    if (inputData) {
      const data = {};
      for (const val of inputData) {
        Object.assign(data, val);
      }
      return data;
    }
    return [];
  }

  useEffect(() => {
    const fetchOverlapAnalysis = async () => {
      try {
        if (selectedRegulation !== '') {
          /*As per FEATURE 1135102 overlap analysis widget should display the same view as in case of “CSRD” being selected, when "All" option is selected*/
          if (selectedRegulation === ALL) {
            selectedRegulation = defaultRegulation;
          }
          const overlapAnalysisData = await homePageRequests.getOverlapAnalysis(selectedRegulation);

          setOverlapAnalysisValues(
            formatOverlapAnalysisData((await overlapAnalysisData).getValue().overlapAnalysis)
          );
        }
      } catch (error) {
        console.log('error', error);
      }
    };
    fetchOverlapAnalysis();

    if (homePageData?.frameworksRegulations.length) {
      setActualRegulations(homePageData?.frameworksRegulations);
    }
  }, [homePageData, selectedRegulation]);

  return (
    <>
      {selectedRegulation && overlapAnalysisValues && (
        <OverlapAnalysis
          frameworksRegulations={actualRegulations}
          selectedRegulation={selectedRegulation === ALL ? defaultRegulation : selectedRegulation}
          overLapData={overlapAnalysisValues}
        />
      )}
    </>
  );
};
