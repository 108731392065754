import clsx from 'clsx';
import WidgetHeading from 'components/pageContents/atoms/widgetHeading/widgetHeading.component';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ALL } from 'shared/helpers/constants';
import { Obligation } from 'shared/models/HomePageModel';
import { useAppSelector } from 'shared/redux/hooks';
import { selectHomePageData } from 'shared/redux/slice/homePageDataSlice';
import { RootState } from 'shared/redux/store';

import styles from './totalQualitativeRequirements.module.scss';

const TotalQualitativeRequirements = (): JSX.Element => {
  const [totalQualitativeRequirements, setTotalQualitativeRequirements] = useState<any>(null);

  // fetching store data
  const { homePageData } = useAppSelector(selectHomePageData);
  const { qualitativeObligations } = homePageData || {};

  const selectedRegulation = useSelector((state: RootState) => {
    return state.homePageConfigSlice.selectedRegulation;
  });

  useEffect(() => {
    if (selectedRegulation && qualitativeObligations) {
      const allOption = {
        regulationName: ALL,
        value: qualitativeObligations.reduce((acc: number, curr: any) => acc + curr.value, 0)
      };
      const updatedQualitativeRequirements = [...qualitativeObligations, allOption];
      const selectedMetrics = updatedQualitativeRequirements.filter(
        (value: Obligation) => value.regulationName == selectedRegulation
      );
      if (selectedMetrics.length > 0) {
        setTotalQualitativeRequirements(selectedMetrics[0].value);
      }
    }
  }, [selectedRegulation, qualitativeObligations]);

  return (
    <div className="motif-container no-padding">
      <WidgetHeading title="Total Qualitative Requirements" />
      <div className={clsx(styles.innerContainer, 'motif-row')}>
        <h1 className={clsx(styles.noHeight, 'motif-h1', 'no-padding')}>
          {totalQualitativeRequirements}
        </h1>
      </div>
    </div>
  );
};
export default TotalQualitativeRequirements;
