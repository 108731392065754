import clsx from 'clsx';
import WidgetHeading from 'components/pageContents/atoms/widgetHeading/widgetHeading.component';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ALL } from 'shared/helpers/constants';
import { DefinedDisclosureMetricsModel } from 'shared/models/HomePageModel';
import { useAppSelector } from 'shared/redux/hooks';
import { selectHomePageData } from 'shared/redux/slice/homePageDataSlice';
import { RootState } from 'shared/redux/store';

import styles from './definedMetric.module.scss';

const DefinedMetric = (): JSX.Element => {
  const [reportingMetrics, setReportingMetrics] = useState<any>(null);

  // fetching store data
  const { homePageData } = useAppSelector(selectHomePageData);
  const { definedDisclosureMetrics } = homePageData || {};

  const selectedRegulation = useSelector((state: RootState) => {
    return state.homePageConfigSlice.selectedRegulation;
  });

  const reportingMetricsData: any = {
    primary: 'Primary',
    secondary: 'Intermediate',
    coreData: 'Core Data'
  };

  useEffect(() => {
    if (selectedRegulation && definedDisclosureMetrics) {
      const selectedMetrics = definedDisclosureMetrics
        .concat({
          regulationName: ALL,
          metrics: definedDisclosureMetrics.reduce((acc: any, curr: any) => {
            Object.keys(curr.metrics).forEach((key) => {
              acc[key] = (acc[key] || 0) + curr.metrics[key];
            });
            return acc;
          }, {})
        })
        .filter(
          (value: DefinedDisclosureMetricsModel) => value.regulationName == selectedRegulation
        );
      if (selectedMetrics.length > 0) {
        setReportingMetrics(selectedMetrics[0].metrics);
      }
    }
  }, [selectedRegulation, definedDisclosureMetrics]);

  return (
    <div className="motif-container no-padding">
      <WidgetHeading
        info={{
          show: true,
          tooltipPlacement: 'bottom',
          tooltipContent: [
            '<b>Primary Disclosures-</b> Primary level calculations for quantitative disclosures mapped for the selected reporting framework',
            '<b>Intermediate Attributes-</b> Secondary level calculations (tier 2 calculations) mapped from primary disclosures for the selected reporting framework',
            '<b>Core Data Attributes-</b> Atomic-level data elements required for the primary and secondary level calculation metrics for the selected reporting framework'
          ]
        }}
        title="Quantitative Metrics Analyzed"
      />

      <div className={clsx(styles.innerContainer, 'motif-row')}>
        {reportingMetrics &&
          Object.keys(reportingMetrics).map((metric, index) => {
            return (
              <div
                className={clsx(
                  styles.flexCenter,
                  index > 0 &&
                    index < Object.keys(reportingMetrics).length - 1 &&
                    styles.borderStyle,
                  'motif-col-xs'
                )}
                key={index}>
                <h6
                  className={clsx(
                    styles.noHeight,
                    'motif-h6',
                    'no-padding',
                    styles.metricsHeading
                  )}>
                  {reportingMetricsData[metric]}
                </h6>
                <h1 className={clsx(styles.noHeight, 'motif-h1', 'no-padding')}>
                  {Number(reportingMetrics[metric]).toLocaleString('en-US') || 0}
                </h1>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default DefinedMetric;
